const { v4: uuidv4 } = require('uuid');
const { layers } = require('./layers');
const { variables } = require('./variables');

const variantTypes = {
  Base: 0,
  Segment: 1,
  // Deprecated Product, Store and Language and used in hottable cell types
  Product: 2,
  Store: 3,
  MVT: 4,
  Language: 5
}

const variantImageDensityTypes = {
  Default: 'type-default',
  Comfortable: 'type-comfortable',
  Compact: 'type-compact'
}

const baseVariant = {
  id: uuidv4(),
  meta: {
    name: 'Default',
    language: null
  },
  type: variantTypes.Base,
  layers: [],
  variables: [],
  computedProperties: { product: null, store: null }
};

const macroTypes = {
  Product: 'product',
  Store: 'store'
}

function attributes(data) {
  return (data && Object.keys(data)) || [];
}

function macroExpression(attributes, macroType) {
  if (attributes.length > 0) {
    // Allow both space and underscore as phrase attribute delimiter
    switch(macroType) {
      case macroTypes.Product:
        return new RegExp(`{{\\s*product.(${attributes.map(a => a.replace('_', '[_\\s]*')).join('|')})\\s*}}`, 'i');
      case macroTypes.Store:
        return new RegExp(`{{\\s*store.(${attributes.map(a => a.replace('_', '[_\\s]*')).join('|')})\\s*}}`, 'i');
    }
  } else {
    return new RegExp(/(?!)/);
  }
}

// Node doesn't accept only getters for object properties. Setting dummy setters for now.
const computedVariants = {
  mvt: {
    collection: [],
    selected: null,
    getCombination(id) {
      if (id < 1 || id > this.length) throw new RangeError();
      return this.collection.reduceRight(({ combination, offset }, layerVariants) => {
        if (Array.isArray(layerVariants) && layerVariants.length) {
          combination.unshift(
            layerVariants[parseInt(((id - 1) % (layerVariants.length * offset)) / offset)]
          )
          offset *= layerVariants.length;
        }
        return { combination, offset }
      },
      {
        combination: [],
        offset: 1
      }).combination;
    },
    get length() {
      return this.collection.reduce((total, layerVariants) => {
        return (Array.isArray(layerVariants) && layerVariants.length) ? (total * layerVariants.length) : total;
      }, 1)
    },
    set length(values) {
      // do nothing
    },
    get combinations() {
      return new Array(this.length)
        .fill(null)
        .map((_, i) => ({ id: i + 1, name: `Combination #${i + 1}` }))
    },
    set combinations(values) {
      // do nothing
    }
  }
}

// For usage fetching product in externalTools
const AdvancedSettingsFilterTypes = Object.freeze({
  INCLUDE: { id: 1, name: 'includes' },
  EXCLUDE: { id: 2, name: 'excludes' },
  STARTS_WITH: { id: 3, name: 'starts with' },
  GREATER_THAN: { id: 4, name: 'greater than' },
  LESSER_THAN: { id: 5, name: 'lesser than' },
  EQUALS: { id: 6, name: 'equals' },
  DOESNOTEQUALS: { id: 7, name: 'not equals' }
})

const additionalVariantProperties = [
  {
    name: 'Language',
    type: variantTypes.Language,
    enabled: false
  },
  {
    name: 'Product',
    type: variantTypes.Product,
    enabled: false
  },
  {
    name: 'Store',
    type: variantTypes.Store,
    enabled: false
  }
]

const variantConfigurationBase = {
  variantType: variantTypes.Base,
  variants: [
    baseVariant
  ],
  baseVariant,
  selectedVariant: Object.values(variantTypes).reduce((sv, typeId) => (sv[typeId] = baseVariant) && sv, {}),
  primaryVariant: Object.values(variantTypes).reduce((pv, typeId) => (pv[typeId] = baseVariant) && pv, {}),
  computedVariants
}

// Keeping values as objects for these maps to make adding properties easier
// Only properties listed here will be shown in the table (because we need equivalent language-based label)
// Properties not present here but part of API response for GET /products can still be used in macros
const variantProductAttributesMap = {
  id: { mapping: 'id' },
  imageLink: { mapping: 'imageLink' },
  searchEngineProductId: { mapping: 'searchEngineProductId' },
  itemGroupId: { mapping: 'itemGroupId' },
  title: { mapping: 'title' },
  description: { mapping: 'description' },
  availability: { mapping: 'availability' },
  condition: { mapping: 'condition' },
  brand: { mapping: 'brand'  },
  ageGroup: { mapping: 'ageGroup' },
  gender: { mapping: 'gender' },
  googleProductCategory: { mapping: 'googleProductCategory' },
  material: { mapping: 'material' },
  pattern: { mapping: 'pattern' },
  productType: { mapping: 'productType' },
  salePrice: { mapping: 'salePrice' },
  price: { mapping: 'price' },
  mpn: { mapping: 'mpn' },
  gtin: { mapping: 'gtin' },
  link: { mapping: 'link' },
  additionalImageLink: { mapping: 'additionalImageLink' },
  color: { mapping: 'color' },
  size: { mapping: 'size' },
  sku: { mapping: 'sku' }
}

const variantStoreAttributesMap = {
  id: { mapping: 'id' },
  storeCode: { mapping: 'storeCode' },
  name: { mapping: 'name' },
  phoneNumber: { mapping: 'phoneNumber' },
  address: { mapping: 'address' },
  area: { mapping: 'area' },
  city: { mapping: 'city' },
  state: { mapping: 'state' },
  country: { mapping: 'country' },
  postalCode: { mapping: 'postalCode' },
  emailAddress: { mapping: 'emailAddress' },
  website: { mapping: 'website' },
  latitude: { mapping: 'latitude' },
  longitude: { mapping: 'longitude' },
  radius: { mapping: 'radius' },
  radiusUnit: { mapping: 'radiusUnit' },
  facebookLocationId: { mapping: 'facebookLocationId' },
  facebookPageId: { mapping: 'facebookPageId' },
  trackingPixel: { mapping: 'trackingPixel' },
  instagramAccountId: { mapping: 'instagramAccountId' },
  googleMerchantId: { mapping: 'googleMerchantId' },
  keywords: { mapping: 'keywords' },
  timingMonday: { mapping: 'timingMonday' },
  timingTuesday: { mapping: 'timingTuesday' },
  timingWednesday: { mapping: 'timingWednesday' },
  timingThursday: { mapping: 'timingThursday' },
  timingFriday: { mapping: 'timingFriday' },
  timingSaturday: { mapping: 'timingSaturday' },
  timingSunday: { mapping: 'timingSunday' },
  startDate: { mapping: 'startDate' },
  endDate: { mapping: 'endDate' }
}

function getVariantName(variant) {
  switch(this.variantType) {
    case variantTypes.MVT:
      return `${variant.id} / ${this.mvtVariants.length}`;
    default:
      return variant.meta.name;
  }
}

const variantPropertyGroups = {
  CONTENT: 'Content',
  STYLE: 'Style',
  LAYOUT: 'Layout',
  TEXTSTYLE: 'Text Style',
  BUTTONSTYLE: 'Button Style'
};

const variantPropertyTypes = {
  NUMBER: 'number',
  STRING: 'string',
  HEX: 'hex',
  URL: 'url',
  BOOLEAN: 'boolean',
  ARRAY: 'array',
  OBJECT: 'object'
};

const variantProperties = {
  text: {
    id: 'text',
    displayName: 'Text',
    group: variantPropertyGroups.CONTENT,
    path: 'text',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: true,
    editable: true,
    supportsFocusMode: false
  },
  font: {
    id: 'font',
    displayName: 'Font',
    group: variantPropertyGroups.STYLE,
    path: 'styles.fontFamily',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  fontSize: {
    id: 'fontSize',
    displayName: 'Font Size',
    group: variantPropertyGroups.STYLE,
    path: 'styles.scaledFontSize',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  strokeWidth: {
    id: 'strokeWidth',
    displayName: 'Stroke Width',
    group: variantPropertyGroups.STYLE,
    path: 'styles.strokeWidth',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: false
  },
  fill: {
    id: 'fill',
    displayName: 'Fill',
    group: variantPropertyGroups.STYLE,
    path: 'styles.fill',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  stroke: {
    id: 'stroke',
    displayName: 'Stroke',
    group: variantPropertyGroups.STYLE,
    path: 'styles.stroke',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  backgroundColor: {
    id: 'backgroundColor',
    displayName: 'Background Color',
    group: variantPropertyGroups.STYLE,
    path: 'styles.textBackgroundColor',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  opacity: {
    id: 'opacity',
    displayName: 'Opacity',
    group: variantPropertyGroups.STYLE,
    path: 'styles.opacity',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  textWidth: {
    id: 'textWidth',
    displayName: 'Width',
    group: variantPropertyGroups.LAYOUT,
    path: 'styles.width',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  textCase: {
    id: 'textCase',
    displayName: 'Text Case',
    group: variantPropertyGroups.STYLE,
    path: 'styles.textCase',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  rotate: {
    id: 'rotate',
    displayName: 'Rotate',
    group: variantPropertyGroups.LAYOUT,
    path: 'styles.angle',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  image: {
    id: 'image',
    displayName: 'Image',
    group: variantPropertyGroups.CONTENT,
    path: 'props.imageUrl',
    value: '',
    type: variantPropertyTypes.URL,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  imageId: {
    id: 'imageId',
    displayName: 'Image Id',
    group: variantPropertyGroups.CONTENT,
    path: 'props.imageId',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  businessProfileId: {
    id: 'businessProfileId',
    displayName: 'Business Profile Id',
    group: variantPropertyGroups.CONTENT,
    path: 'props.businessProfileId',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  width: {
    id: 'width',
    displayName: 'Width',
    group: variantPropertyGroups.CONTENT,
    path: 'props.width',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  height: {
    id: 'height',
    displayName: 'Height',
    group: variantPropertyGroups.CONTENT,
    path: 'props.height',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  scaleX: {
    id: 'scaleX',
    displayName: 'ScaleX',
    group: variantPropertyGroups.LAYOUT,
    path: 'styles.scaleX',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  scaleY: {
    id: 'scaleY',
    displayName: 'ScaleY',
    group: variantPropertyGroups.LAYOUT,
    path: 'styles.scaleY',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  blendMode: {
    id: 'blendMode',
    displayName: 'Blend Mode',
    group: variantPropertyGroups.STYLE,
    path: 'filters.globalBlend.operation',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  bgRemovedUrl: {
    id: 'bgRemovedUrl',
    displayName: 'Bg Removed Url',
    group: variantPropertyGroups.STYLE,
    path: 'filters.bgRemove.bgRemovedUrl',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  fgRemovedUrl: {
    id: 'fgRemovedUrl',
    displayName: 'Fg Removed Url',
    group: variantPropertyGroups.STYLE,
    path: 'filters.inpaintForeground.fgRemovedUrl',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  buttonText: {
    id: 'buttonText',
    displayName: 'Text',
    group: variantPropertyGroups.CONTENT,
    path: 'text.text',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: true,
    editable: true,
    supportsFocusMode: false
  },
  buttonFont: {
    id: 'buttonFont',
    displayName: 'Font',
    group: variantPropertyGroups.TEXTSTYLE,
    path: 'text.styles.fontFamily',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  buttonFontSize: {
    id: 'buttonFontSize',
    displayName: 'Font Size',
    group: variantPropertyGroups.TEXTSTYLE,
    path: 'text.styles.scaledFontSize',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  buttonTextFill: {
    id: 'buttonTextFill',
    displayName: 'Text Fill',
    group: variantPropertyGroups.TEXTSTYLE,
    path: 'text.styles.fill',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  buttonTextStroke: {
    id: 'buttonTextStroke',
    displayName: 'Text Stroke',
    group: variantPropertyGroups.TEXTSTYLE,
    path: 'text.styles.stroke',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  buttonTextStrokeWidth: {
    id: 'buttonTextStrokeWidth',
    displayName: 'Text Stroke Width',
    group: variantPropertyGroups.TEXTSTYLE,
    path: 'text.styles.strokeWidth',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: false
  },
  buttonStyle: {
    id: 'buttonStyle',
    displayName: 'Outline',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.outline',
    value: false,
    type: variantPropertyTypes.BOOLEAN,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  buttonShape: {
    id: 'buttonShape',
    displayName: 'Shape',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.cornerStyle',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  buttonSize: {
    id: 'buttonSize',
    displayName: 'Size',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: '',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  buttonWidth: {
    id: 'buttonWidth',
    displayName: 'Width',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.scaledWidth',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  buttonHeight: {
    id: 'buttonHeight',
    displayName: 'Height',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.scaledHeight',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  buttonColor: {
    id: 'buttonColor',
    displayName: 'Color',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.fill',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  cornerRadiusX: {
    id: 'cornerRadiusX',
    displayName: 'Corner Radius X',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.rxCornerRadius',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  cornerRadiusY: {
    id: 'cornerRadiusY',
    displayName: 'Corner Radius Y',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'boundingRectangle.ryCornerRadius',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  buttonRotate: {
    id: 'buttonRotate',
    displayName: 'Rotate',
    group: variantPropertyGroups.LAYOUT,
    path: 'boundingRectangle.angle',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  buttonBlendMode: {
    id: 'buttonBlendMode',
    displayName: 'Blend Mode',
    group: variantPropertyGroups.BUTTONSTYLE,
    path: 'filters.globalBlend.operation',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  shapeType: {
    id: 'shapeType',
    displayName: 'ShapeType',
    group: variantPropertyGroups.CONTENT,
    path: 'type',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false
  },
  shape: {
    id: 'shape',
    displayName: 'Shape',
    group: variantPropertyGroups.CONTENT,
    path: 'path',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  shapeRotate: {
    id: 'shapeRotate',
    displayName: 'Rotate',
    group: variantPropertyGroups.LAYOUT,
    path: 'styles.angle',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  shapeRadius: {
    id: 'shapeRadius',
    displayName: 'Radius',
    group: variantPropertyGroups.STYLE,
    path: 'styles.radius',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true
  },
  shapeCornerRadiusY: {
    id: 'shapeCornerRadiusY',
    displayName: 'Corner Radius - Y',
    group: variantPropertyGroups.STYLE,
    path: 'styles.ry',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true
  },
  shapeCornerRadiusX: {
    id: 'shapeCornerRadiusX',
    displayName: 'Corner Radius - X',
    group: variantPropertyGroups.STYLE,
    path: 'styles.rx',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true
  },
  shapeHeight: {
    id: 'shapeHeight',
    displayName: 'Height',
    group: variantPropertyGroups.STYLE,
    path: 'styles.height',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true
  },
  shapeWidth: {
    id: 'shapeWidth',
    displayName: 'Width',
    group: variantPropertyGroups.STYLE,
    path: 'styles.width',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true
  },
  polygonPoints: {
    id: 'polygonPoints',
    displayName: 'Points',
    group: variantPropertyGroups.CONTENT,
    path: 'polygonPoints',
    value: [],
    type: variantPropertyTypes.ARRAY,
    hasValidations: false,
    editable: true
  },
  lineCoordinates: {
    id: 'lineCoordinates',
    displayName: 'Coordinates',
    group: variantPropertyGroups.CONTENT,
    path: 'lineCoordinates',
    value: [],
    type: variantPropertyTypes.ARRAY,
    hasValidations: false,
    editable: true
  },
  video: {
    id: 'video',
    displayName: 'Video',
    group: variantPropertyGroups.CONTENT,
    path: 'props.src',
    value: '',
    type: variantPropertyTypes.URL,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  duration: {
    id: 'duration',
    displayName: 'Duration',
    group: variantPropertyGroups.CONTENT,
    path: 'props.duration',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  loop: {
    id: 'loop',
    displayName: 'Loop',
    group: variantPropertyGroups.CONTENT,
    path: 'props.loop',
    value: false,
    type: variantPropertyTypes.BOOLEAN,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  videoRotate: {
    id: 'videoRotate',
    displayName: 'Rotate',
    group: variantPropertyGroups.LAYOUT,
    path: 'styles.angle',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  audio: {
    id: 'audio',
    displayName: 'Audio',
    group: variantPropertyGroups.CONTENT,
    path: 'props.src',
    value: '',
    type: variantPropertyTypes.URL,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  audioTtsText: {
    id: 'audioTtsText',
    displayName: 'TTS text',
    group: variantPropertyGroups.CONTENT,
    path: 'props.ttsConfiguration.text',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  audioTtsData: {
    id: 'audioTtsData',
    displayName: 'TTS Data',
    group: variantPropertyGroups.CONTENT,
    path: 'props.ttsConfiguration.data',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  language: {
    id: 'language',
    displayName: 'Language',
    group: variantPropertyGroups.STYLE,
    path: 'props.ttsConfiguration.voice.languageCode',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  voiceName: {
    id: 'voiceName',
    displayName: 'Voice Name',
    group: variantPropertyGroups.STYLE,
    path: 'props.ttsConfiguration.voice.name',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  speed: {
    id: 'speed',
    displayName: 'Speed',
    group: variantPropertyGroups.STYLE,
    path: 'props.ttsConfiguration.speakingRate',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  pitch: {
    id: 'pitch',
    displayName: 'Pitch',
    group: variantPropertyGroups.STYLE,
    path: 'props.ttsConfiguration.pitch',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: true,
    editable: true,
    supportsFocusMode: true
  },
  subtitle: {
    id: 'subtitle',
    displayName: 'Subtitle',
    group: variantPropertyGroups.CONTENT,
    path: 'text',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  backgroundImage: {
    id: 'backgroundImage',
    displayName: 'Image',
    group: variantPropertyGroups.CONTENT,
    path: 'imageProps.imageUrl',
    value: '',
    type: variantPropertyTypes.URL,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  backgroundImageId: {
    id: 'backgroundImageId',
    displayName: 'Image Id',
    group: variantPropertyGroups.CONTENT,
    path: 'imageProps.imageId',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  backgroundImageBusinessProfileId: {
    id: 'backgroundImageBusinessProfileId',
    displayName: 'Business Profile Id',
    group: variantPropertyGroups.CONTENT,
    path: 'imageProps.businessProfileId',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  backgroundImageWidth: {
    id: 'backgroundImageWidth',
    displayName: 'Width',
    group: variantPropertyGroups.CONTENT,
    path: 'imageProps.width',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  backgroundImageHeight: {
    id: 'backgroundImageHeight',
    displayName: 'Height',
    group: variantPropertyGroups.CONTENT,
    path: 'imageProps.height',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  colorProps: {
    id: 'colorProps',
    displayName: 'Color',
    group: variantPropertyGroups.STYLE,
    path: 'colorProps.colors.rgba',
    value: '',
    type: variantPropertyTypes.HEX,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  backgroundImageFit: {
    id: 'backgroundImageFit',
    displayName: 'Fit',
    group: variantPropertyGroups.STYLE,
    path: 'imageProps.selectedFit',
    value: 0,
    type: variantPropertyTypes.NUMBER,
    hasValidations: false,
    editable: true,
    supportsFocusMode: true
  },
  mediaId: {
    id: 'mediaId',
    displayName: 'Id',
    group: variantPropertyGroups.CONTENT,
    path: 'props.id',
    value: '',
    type: variantPropertyTypes.STRING,
    hasValidations: false,
    editable: false,
    supportsFocusMode: true
  },
  imageSources: {
    id: 'imageSources',
    displayName: 'Images',
    group: variantPropertyGroups.CONTENT,
    path: 'props.sources',
    value: [],
    type: variantPropertyTypes.ARRAY,
    hasValidations: false,
    editable: true
  },
  isSplitByGrapheme: {
    id: 'splitId',
    displayName: 'Split By Character',
    group: variantPropertyGroups.STYLE,
    path: 'styles.splitByGrapheme',
    value: false,
    type: variantPropertyTypes.BOOLEAN,
    hasValidations: false,
    editable: true,
    supportsFocusMode: false
  },
  translate: {
    id: 'translate',
    displayName: 'Translate',
    group: variantPropertyGroups.CONTENT,
    path: '',
    value: true,
    type: variantPropertyTypes.BOOLEAN,
    hasValidations: true,
    editable: true
  },
  absolutePosition: {
    id: 'absolutePosition',
    displayName: 'Absolute position',
    group: variantPropertyGroups.LAYOUT,
    path: 'absolutePosition',
    value: null,
    type: variantPropertyTypes.OBJECT,
    hasValidations: true,
    editable: true
  },
  visibility: {
    id: 'visibility',
    displayName: 'Visibility',
    group: variantPropertyGroups.CONTENT,
    path: 'hidden',
    value: false,
    type: variantPropertyTypes.BOOLEAN,
    hasValidations: false,
    editable: true
  },
  audioTtsApiCallRequired: {
    id: 'audioTtsApiCallRequired',
    displayName: 'Audio TTS Api Call Required',
    group: variantPropertyGroups.CONTENT,
    path: 'props.ttsConfiguration.isApiCallRequired',
    value: false,
    type: variantPropertyTypes.BOOLEAN,
    hasValidations: false,
    editable: false
  }
};

const variantPropertyPaths = Object.keys(variantProperties).reduce((variantPropertyPaths, key) => {
  variantPropertyPaths[key] = variantProperties[key].path;
  return variantPropertyPaths;
}, {});

const variantLayerProperties = {
  [layers.text]: [
    variantProperties.text,
    variantProperties.font,
    variantProperties.fontSize,
    variantProperties.strokeWidth,
    variantProperties.textCase,
    variantProperties.fill,
    variantProperties.stroke,
    variantProperties.backgroundColor,
    variantProperties.textWidth,
    variantProperties.rotate,
    variantProperties.blendMode,
    variantProperties.isSplitByGrapheme,
    variantProperties.translate,
    variantProperties.absolutePosition,
    variantProperties.visibility
  ],
  [layers.button]: [
    variantProperties.buttonText,
    variantProperties.buttonFont,
    variantProperties.buttonTextFill,
    variantProperties.buttonTextStroke,
    variantProperties.buttonTextStrokeWidth,
    variantProperties.buttonStyle,
    variantProperties.buttonShape,
    variantProperties.buttonColor,
    variantProperties.cornerRadiusX,
    variantProperties.cornerRadiusY,
    variantProperties.buttonRotate,
    variantProperties.buttonBlendMode,
    variantProperties.translate,
    variantProperties.absolutePosition,
    variantProperties.visibility
  ],
  [layers.shape]: [
    variantProperties.shape,
    variantProperties.shapeType,
    variantProperties.polygonPoints,
    variantProperties.lineCoordinates,
    variantProperties.stroke,
    variantProperties.fill,
    variantProperties.strokeWidth,
    variantProperties.opacity,
    variantProperties.scaleX,
    variantProperties.scaleY,
    variantProperties.shapeRotate,
    variantProperties.blendMode,
    variantProperties.shapeRadius,
    variantProperties.shapeCornerRadiusY,
    variantProperties.shapeCornerRadiusX,
    variantProperties.shapeHeight,
    variantProperties.shapeWidth,
    variantProperties.absolutePosition,
    variantProperties.visibility
  ],
  [layers.image]: [
    variantProperties.image,
    variantProperties.imageId,
    variantProperties.businessProfileId,
    variantProperties.width,
    variantProperties.height,
    variantProperties.rotate,
    variantProperties.opacity,
    variantProperties.blendMode,
    variantProperties.bgRemovedUrl,
    variantProperties.fgRemovedUrl,
    variantProperties.imageSources,
    variantProperties.absolutePosition,
    variantProperties.visibility
  ],
  [layers.video]: [
    variantProperties.video,
    variantProperties.mediaId,
    variantProperties.width,
    variantProperties.height,
    variantProperties.duration,
    variantProperties.loop,
    variantProperties.videoRotate,
    variantProperties.blendMode,
    variantProperties.absolutePosition,
    variantProperties.visibility
  ],
  [layers.audio]: [
    variantProperties.audio,
    variantProperties.mediaId,
    variantProperties.duration,
    variantProperties.audioTtsText,
    variantProperties.audioTtsData,
    variantProperties.language,
    variantProperties.voiceName,
    variantProperties.pitch,
    variantProperties.speed,
    variantProperties.visibility,
    variantProperties.audioTtsApiCallRequired
  ],
  [layers.subtitle]: [variantProperties.subtitle],
  [layers.background]: [
    variantProperties.backgroundImage,
    variantProperties.backgroundImageId,
    variantProperties.backgroundImageBusinessProfileId,
    variantProperties.backgroundImageWidth,
    variantProperties.backgroundImageHeight,
    variantProperties.colorProps,
    variantProperties.backgroundImageFit,
    variantProperties.visibility
  ]
};

const variantVariableProperties = {
  [variables.text]: [
    variantProperties.text,
    variantProperties.font,
    variantProperties.fontSize,
    variantProperties.fill,
    variantProperties.isSplitByGrapheme
  ]
};

const variantLayerPropertyDefaults = {
  [layers.text]: [variantProperties.text],
  [layers.button]: [variantProperties.buttonText],
  [layers.shape]: [variantProperties.shape, variantProperties.shapeType],
  [layers.image]:
    [
      variantProperties.image,
      variantProperties.imageId,
      variantProperties.businessProfileId,
      variantProperties.width,
      variantProperties.height,
      variantProperties.bgRemovedUrl,
      variantProperties.fgRemovedUrl
    ],
  [layers.video]:
  [
    variantProperties.video,
    variantProperties.mediaId,
    variantProperties.width,
    variantProperties.height,
    variantProperties.duration
  ],
  [layers.audio]:
  [
    variantProperties.audio,
    variantProperties.mediaId,
    variantProperties.duration
  ],
  [layers.subtitle]: [variantProperties.subtitle],
  [layers.background]:
  [
    variantProperties.colorProps
  ]
};

const variantVariablePropertyDefaults = {
  [variables.text]: [variantProperties.text]
};

const requireDependentProperties = [
  variantProperties.image,
  variantProperties.video,
  variantProperties.audio,
  variantProperties.shape,
  variantProperties.backgroundImage,
  variantProperties.audioTtsText,
  variantProperties.language,
  variantProperties.voiceName
];

const scaledProperties = [
  variantPropertyPaths.fontSize,
  variantPropertyPaths.scaleX,
  variantPropertyPaths.scaleY,
  variantPropertyPaths.buttonFontSize,
  variantPropertyPaths.buttonWidth,
  variantPropertyPaths.buttonHeight,
  variantPropertyPaths.opacity
];

const audioTtsProps = [
  variantProperties.audioTtsText,
  variantProperties.language,
  variantProperties.voiceName,
  variantProperties.pitch,
  variantProperties.speed
];

//Gets layerData property name from path. Used for updating multiple sources festure.
const propertyName = (path) => {
  const variantPropertyName = Object.keys(variantPropertyPaths).find(key => variantPropertyPaths[key] === path);
  if (variantPropertyName == 'image')
    return 'imageUrl';
  return variantPropertyName;
}

const feedSheetTypes = {
  GOOGLE_SHEET: 1
}

const hotDataChangeSources = {
  LOADDATA: 'loadData',
  EDIT: 'edit',
  PASTE: 'CopyPaste.paste',
  AUTOFILL: 'Autofill.fill'
};

module.exports = {
  variantTypes,
  variantImageDensityTypes,
  baseVariant,
  macroTypes,
  attributes,
  macroExpression,
  computedVariants,
  AdvancedSettingsFilterTypes,
  additionalVariantProperties,
  variantConfigurationBase,
  variantProductAttributesMap,
  variantStoreAttributesMap,
  getVariantName,
  variantPropertyGroups,
  variantPropertyTypes,
  variantProperties,
  variantPropertyPaths,
  variantLayerProperties,
  variantVariableProperties,
  variantLayerPropertyDefaults,
  variantVariablePropertyDefaults,
  requireDependentProperties,
  scaledProperties,
  propertyName,
  audioTtsProps,
  hotDataChangeSources,
  feedSheetTypes
};
